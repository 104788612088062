body {
  font-family: 'Playfair Display', serif !important;
}

#root {
  padding: 0;
  margin: 0;
}

.card-collection {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin: auto;
  width: 100%;
}

.nav-item {
  padding: 10px;
  font-size: 18px;
  font-weight: 500;
}

.nav-container {
  display: flex;
  flex-direction: row !important;
}
.card-body {
  font-family: 'Saira Extra Condensed', sans-serif;
  font-size: 1.3rem !important;
}

.standout {
  box-shadow:  rgb(51,51, 51) 0px 0px 0px 2px ;
}



h1, h2, h3, h4, h5, h6, p {
  text-align: center ;
}

.team-container {
  font-family: 'Nova Square', sans-serif;

}


@media screen and (max-width: 987px ) {
  .card-collection {
    grid-template-columns: 1fr 1fr;
  }
}
@media only screen and (max-width: 600px) {

  .card-collection {
    grid-template-columns: 1fr;
  }
}

